import LayoutA6 from "../../components/layout-a6"
import React from "react"
import EnrollInfoDetail from '../../components/enroll-info-detail'

export default class A6EnrollInfoDetail extends React.Component {
  render() {
    const color=this.props.pageContext.element.color
    return(
      <LayoutA6 location={this.props.location} >
        <EnrollInfoDetail  color={color}/>
      </LayoutA6>
    )
  }
}