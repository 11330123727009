
import React from "react"
import detailStyles from "./enroll-info-detail.module.css"
import PropTypes from "prop-types"

export default class EnrollInfoDetail extends React.Component {
  static propTypes={
    color:PropTypes.string.isRequired
  }
  render() {
    const color=this.props.color
    return(
        <div className={detailStyles.profileBody}>
          <div className={detailStyles.profileContainer}>
            <div className={detailStyles.enrollInfoContainer}>
              <div className={detailStyles.infoTitle}>报名信息</div>
              <div className={detailStyles.name}>张迈</div>
              <div className={detailStyles.idNum}>
                <img alt="证件号" src={require('../images/shouji.svg')}/>
                <div>320123100920100039</div>
              </div>
              <div className={detailStyles.phoneNum}>
                <img alt="手机号" src={require('../images/shouji.svg')}/>
                <div>18556098704</div>
              </div>
              {/*<div style={{borderBottom:'1px solid #e5e5e5'}}></div>*/}
              <div className={detailStyles.confirmRacerInfo}>
                <div>确认选手信息</div>
                <div className={detailStyles.centerLine}></div>
              </div>
              <div className={detailStyles.racerEnrollInfo}>
                <div className={detailStyles.racerEnrollInfoTitle}>
                  <div className={detailStyles.titleVerticalLine} ></div>
                  <div>证件信息</div>
                </div>
                <div>
                  <div className={detailStyles.infoItemLeft}>
                    <div className={detailStyles.infoLine}>
                      <div className={detailStyles.item1}>姓名:</div>
                      <div className={detailStyles.item2}>张迈</div>
                    </div>
                    <div className={detailStyles.infoLine}>
                      <div className={detailStyles.item1}>证件类型:</div>
                      <div className={detailStyles.item2}>暂无</div>
                    </div>
                    <div className={detailStyles.infoLine}>
                      <div className={detailStyles.item1}>出生日期:</div>
                      <div className={detailStyles.item2}>暂无</div>
                    </div>
                  </div>
                  <div  className={detailStyles.infoItemRight}>
                    <div className={detailStyles.infoLine}>
                      <div className={detailStyles.item1}>性别:</div>
                      <div className={detailStyles.item2}>男</div>
                    </div>
                    <div className={detailStyles.infoLine}>
                      <div className={detailStyles.item1}>证件号码:</div>
                      <div className={detailStyles.item2}>暂无</div>
                    </div>
                  </div>
                  <div style={{clear:'both'}}></div>
                </div>
              </div>
              <div className={detailStyles.borderLine}></div>
              <div className={detailStyles.racerEnrollInfo}>
                <div className={detailStyles.orderInfoTitle}>
                  <div className={detailStyles.titleVerticalLine}></div>
                  <div >个人信息</div>
                </div>
                <div>
                  <div className={detailStyles.infoItemLeft}>
                    <div className={detailStyles.infoLine}>
                      <div className={detailStyles.item1}>血型:</div>
                      <div className={detailStyles.item2}></div>
                    </div>
                    <div className={detailStyles.infoLine}>
                      <div className={detailStyles.item1}>省份:</div>
                      <div className={detailStyles.item2}></div>
                    </div>
                    <div className={detailStyles.infoLine}>
                      <div className={detailStyles.item1}>职业:</div>
                      <div className={detailStyles.item2}></div>
                    </div>
                    <div className={detailStyles.infoLine}>
                      <div className={detailStyles.item1}>月收入:</div>
                      <div className={detailStyles.item2}></div>
                    </div>
                  </div>
                  <div className={detailStyles.infoItemRight}>
                    <div className={detailStyles.infoLine}>
                      <div className={detailStyles.item1}>国家地区:</div>
                      <div className={detailStyles.item2}></div>
                    </div>
                    <div className={detailStyles.infoLine}>
                      <div className={detailStyles.item1}>学历:</div>
                      <div className={detailStyles.item2}></div>
                    </div>
                    <div className={detailStyles.infoLine}>
                      <div className={detailStyles.item1}>居住地:</div>
                      <div className={detailStyles.item2}></div>
                    </div>
                    <div className={detailStyles.infoLine}>
                      <div className={detailStyles.item1}></div>
                      <div className={detailStyles.item2}></div>
                    </div>
                  </div>
                  <div style={{clear:'both'}}></div>
                </div>
              </div>
              <div className={detailStyles.borderLine}></div>
              <div className={detailStyles.racerEnrollInfo}>
                <div className={detailStyles.orderInfoTitle}>
                  <div className={detailStyles.titleVerticalLine}></div>
                  <div >联系方式</div>
                </div>
                <div>
                  <div className={detailStyles.infoItemLeft}>
                    <div className={detailStyles.infoLine}>
                      <div className={detailStyles.item1}>手机号码:</div>
                      <div className={detailStyles.item2}></div>
                    </div>
                    <div className={detailStyles.infoLine}>
                      <div className={detailStyles.item1}>紧急联系人:</div>
                      <div className={detailStyles.item2}></div>
                    </div>
                    <div className={detailStyles.infoLine}>
                      <div className={detailStyles.item1}>联系人关系:</div>
                      <div className={detailStyles.item2}></div>
                    </div>

                  </div>
                  <div className={detailStyles.infoItemRight}>
                    <div className={detailStyles.infoLine}>
                      <div className={detailStyles.item1}>邮箱:</div>
                      <div className={detailStyles.item2}></div>
                    </div>
                    <div className={detailStyles.infoLine}>
                      <div className={detailStyles.item1}>联系人电话:</div>
                      <div className={detailStyles.item2}></div>
                    </div>
                  </div>
                  <div style={{clear:'both'}}></div>
                </div>
              </div>
              <div className={detailStyles.borderLine}></div>
              <div className={detailStyles.orderInfo}>
                <div className={detailStyles.orderInfoTitle}>
                  <div className={detailStyles.titleVerticalLine}></div>
                  <div >其他信息</div>
                </div>
                <div>
                  <div className={detailStyles.infoItemLeft}>
                    <div className={detailStyles.infoLine}>
                      <div className={detailStyles.item1}>上传成绩证书:</div>
                      <div className={detailStyles.item2}>已上传图片</div>
                    </div>
                  </div>
                  <div style={{clear:'both'}}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}